<template>
  <div class="account-dashboard">
    <div class="account-dashboard__wrapper">
      <Column>
        <Row class="account-dashboard__name-avatar"
             justify="between">
          <Column>
            <Row>
              <span class="account-dashboard__greeting">{{ $t('account.greet') }},
                {{$user.name}}</span>
            </Row>
          </Column>
          <Column>
            <UserAvatar :params="{avatar: $user.avatar, size: $laptop ? 50 : 40}"/>
          </Column>
        </Row>
        <Divider v-if="$mobile"
                 class="account-dashboard__divider"/>
        <Row align="center"
             class="m-b-40"
             :wrap="$mobile"
             justify="between">
          <p class="account-dashboard__added-all-time">{{ $t('account.added_whole_time') }}</p>
          <Button class="grid-md-3 grid-lg-3"
                  :full="$mobile"
                  color="main"
                  v-on:click.native="openPopup">{{ $t('account.add') }}
          </Button>
        </Row>
        <Row :class="$laptop ? 'formation-portal-margin-40' : ''"
             :wrap="$mobile"
             :justify="$laptop ? 'between' : 'start'">
          <Column class="account-dashboard__cell">
            <span class="account-dashboard__value account-dashboard__green">
              {{$my.excursions.length}}</span>
            <span class="account-dashboard__title">
              {{plural($my.excursions.length, [`${$t('account.excursions.p_1')}`,
              `${$t('account.excursions.p_2')}`,`${$t('account.excursions.p_3')}`])}}</span>
          </Column>
          <Column class="account-dashboard__cell">
            <span class="account-dashboard__value account-dashboard__green">
              {{$my.places.length}}</span>
            <span class="account-dashboard__title">
              {{plural($my.places.length, [`${$t('account.objects.p_1')}`,
              `${$t('account.objects.p_2')}`,`${$t('account.objects.p_3')}`])}}
            </span>
          </Column>
          <Column class="account-dashboard__cell">
            <span class="account-dashboard__value">{{0}}</span>
            <span class="account-dashboard__title">
              {{plural(0, [`${$t('account.orgs.p_1')}`,`${$t('account.orgs.p_2')}`,
              `${$t('account.orgs.p_3')}`])}}
            </span>
          </Column>
<!--          <Column class="account-dashboard__cell">-->
<!--            <span class="account-dashboard__value">-->
<!--              {{$my.facts.length}}-->
<!--            </span>-->
<!--            <span class="account-dashboard__title">-->
<!--              {{plural($my.facts.length, ['Факт','Факта','Фактов'])}}-->
<!--            </span>-->
<!--          </Column>-->
        </Row>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountDashboard',
  created() {
    this.$store.dispatch('GET_MY_EXCURSIONS_FROM_SERVER');
    this.$store.dispatch('GET_MY_PLACES_FROM_SERVER');
    this.$store.dispatch('GET_MY_FACTS_FROM_SERVER');
    this.$store.dispatch('GET_MY_AGENCIES_FROM_SERVER');
  },
  methods: {
    plural(n, titles) {
      // eslint-disable-next-line no-nested-ternary,max-len
      return titles[n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    },
    openPopup() {
      this.$store.commit('OPEN_POPUP', {
        width: this.$laptop ? 730 : '100%',
        height: this.$laptop ? 370 : '100%',
        type: 'PopupAddSomething',
      });
    },
  },
};
</script>
